<template>
  <page-layout class="ma-24">
    <!-- 列表 -->
    <div class="bg-white">
      <p-table rowKey="id" :dataSource="dataSource" class="bg-white">
        <p-t-column type="seq" width="60" title="序号" />
        <p-t-column field="flow_no" min-width="120" title="充值订单ID" />
        <p-t-column
          field="amount"
          min-width="120"
          title="充值金额"
          v-slot="{ row }"
          >{{ (row.amount / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column
          field="recharge_time"
          min-width="120"
          title="充值时间"
          v-slot="{ row }"
        >
          <span>{{
            formatToDateTime(row.recharge_time, "yyyy-MM-dd hh:mm:ss")
          }}</span>
        </p-t-column>
      </p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { useGasRechargePageApi } from "@/apis/oil";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default defineComponent({
  name: "account-enterprise-recharge",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const tableDataSource = reactive(getInitTable());

    const gasRechargePage = useGasRechargePageApi();

    const getConsultingList = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...route.query,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await gasRechargePage(data);
        return { records: res.list, total: res.total };
      }
    });

    // 跳转到开票记录详情
    const handleActionClick = row => {
      console.log(row);
      let details = {
        id: row.id,
        name: row.apply_user,
        time: row.apply_time,
        total: row.amount
      };
      router.push({
        path: "/invoice/invoice-detail",
        query: details
      });
    };
    return {
      getConsultingList,
      handleActionClick,
      dataSource: tableDataSource,
      formatToDateTime
    };
  }
});
</script>
